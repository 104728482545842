import { BASE_URL_QA } from "../const";

export const getUserByEmail = async (email: string) => {
    try {
        const response = await fetch(`${BASE_URL_QA}/api/users/email/${email}`);
        
        if (response.status === 404) {
            return null; 
        }
        
        if (!response.ok) {
            throw new Error('Erro ao buscar usuário pelo email');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Erro ao buscar usuário pelo email:', error);
        throw error; // Mantém a exceção para erros de rede ou outros
    }
};

export const addUser = async (userData: { email: string; name: string; roles: string[] }) => {
    try {
        const response = await fetch(`${BASE_URL_QA}/api/users/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        if (!response.ok) {
            throw new Error('Erro ao adicionar usuário');
        }

        return response.json();
    } catch (error) {
        console.error('Erro ao adicionar usuário:', error);
        throw error;
    }
};

export const getAllUsers = async () => {
    try {
        const response = await fetch(`${BASE_URL_QA}/api/users/`);
        if (!response.ok) {
            throw new Error('Erro ao buscar usuários');
        }
        return response.json();
    } catch (error) {
        console.error('Erro ao buscar usuários:', error);
        throw error;
    }
};

export const updateUser = async (id: string, userData: { email: string; name: string; roles: string[] }) => {
    try {
        const response = await fetch(`${BASE_URL_QA}/api/users/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
        });

        if (!response.ok) {
            throw new Error('Erro ao atualizar usuário');
        }

        return response.json();
    } catch (error) {
        console.error('Erro ao atualizar usuário:', error);
        throw error;
    }
};

export const deleteUser = async (id: string) => {
    try {
        const response = await fetch(`${BASE_URL_QA}/api/users/${id}`, {
            method: 'DELETE',
        });

        if (!response.ok) {
            throw new Error('Erro ao excluir usuário');
        }

        return response.json();
    } catch (error) {
        console.error('Erro ao excluir usuário:', error);
        throw error;
    }
};
